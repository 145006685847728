/** @jsx jsx */
import {Image, jsx} from "theme-ui"
import Divider from "../elements/divider"
import Inner from "../elements/inner"
import Content from "../elements/content"
import Svg from "./svg"
import {UpDown, UpDownWide} from "../styles/animations"
// @ts-ignore
import ProjectsMDX from "../sections/projects"
import ProjectCard from "./project-card";

// @ts-ignore
import img1 from './images/s1.png'
// @ts-ignore
import img2 from './images/s2.png'
// @ts-ignore
import img3 from './images/s3.png'
// @ts-ignore
import img4 from './images/s5.png'

const Projects = ({offset, factor = 2}: { offset: number; factor?: number }) => (
    <div className={"projects"}>
        <Divider
            bg="linear-gradient(to right, SlateBlue 0%, DeepSkyBlue 100%)"
            sx={{clipPath: `polygon(0 15%, 100% 25%, 100% 85%, 0 75%)`}}
            speed={-0.2}
            offset={1.1}
            factor={factor}
        />
        <Content speed={0.4} offset={offset + 0.2} factor={factor}>
            <Inner>
                <div
                    sx={{
                        display: `grid`,
                        gridGap: [2, 2, 2, 2],
                        gridTemplateColumns: [`1fr`, `1fr`, `repeat(2, 1fr)`],
                        h2: {gridColumn: `-1/1`, color: `white !important`},
                        margin: [50, 50, 0, 0]
                    }}
                >
                    <ProjectCard link="#">
                        <Image src={img1}/>
                        <h3>Create an excellent Dashboard, see the latest prices
                            in a selected Market and Keep track of your favorite coins.</h3>
                    </ProjectCard>
                    <ProjectCard link="#">
                        <Image src={img3}/>
                        <h3> Follow the highest over all SELL and BUY order limits.</h3>
                    </ProjectCard>
                    <ProjectCard link="#">
                        <Image src={img2}/>
                        <h3> Also you have the regular order book with an extended price-grouping feature.
                        </h3>
                    </ProjectCard>
                    <ProjectCard link="#">
                        <Image src={img4}/>
                        <h3> Find out where the highest SELL and BUY orders are in a range.</h3>
                    </ProjectCard>
                </div>
            </Inner>
        </Content>
        <Divider speed={0.1} offset={offset} factor={factor}>
            <UpDown>
                <Svg icon="23" width={6} left="85%" top="75%"/>
                <Svg icon="24" width={8} left="70%" top="20%"/>
                <Svg icon="25" width={8} left="25%" top="5%"/>
                <Svg icon="26" width={24} left="17%" top="60%"/>
            </UpDown>
            <UpDownWide>
                <Svg icon="27" width={16} left="20%" top="90%"/>
                <Svg icon="28" width={12} left="90%" top="30%"/>
                <Svg icon="29" width={16} left="70%" top="90%"/>
                <Svg icon="30" width={16} left="18%" top="75%"/>
                <Svg icon="31" width={6} left="75%" top="10%"/>
                <Svg icon="32" width={8} left="45%" top="10%"/>
            </UpDownWide>
            <Svg icon="33" width={6} left="4%" top="20%"/>
            <Svg icon="34" width={12} left="80%" top="60%"/>
            <Svg icon="35" width={6} left="10%" top="10%"/>
            <Svg icon="1" width={12} left="29%" top="26%"/>
            <Svg icon="2" width={16} left="75%" top="30%"/>
            <Svg icon="3" width={8} left="80%" top="70%"/>
        </Divider>
    </div>
)

export default Projects

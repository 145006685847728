/** @jsx jsx */
import {jsx} from "theme-ui"
import Divider from "../elements/divider"
import Inner from "../elements/inner"
import Content from "../elements/content"
import Svg from "./svg"
import {UpDown, UpDownWide, waveAnimation} from "../styles/animations"
import Footer from "./footer"
// @ts-ignore
import ContactMDX from "../sections/contact"

const Contact = ({offset, factor = 1}: { offset: number; factor?: number }) => (
    <div>
        <Divider fill="divider" speed={0.2} offset={offset} factor={factor}>
            <div sx={{position: `absolute`, bottom: 0, width: `full`, transform: `matrix(1, 0, 0, -1, 0, 0)`}}>
                <div
                    sx={{
                        position: `relative`,
                        height: `full`,
                        svg: {width: `100%`, height: `40vh`},
                        path: {animation: waveAnimation(`20s`)},
                    }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" id="contact-wave" viewBox="0 0 800 338.05"
                         preserveAspectRatio="none">
                        <path>
                            <animate
                                attributeName="d"
                                values="M 0 100 Q 250 50 400 200 Q 550 350 800 300 L 800 0 L 0 0 L 0 100 Z;M 0 100 Q 200 150 400 200 Q 600 250 800 300 L 800 0 L 0 0 L 0 100 Z;M 0 100 Q 150 350 400 200 Q 650 50 800 300 L 800 0 L 0 0 L 0 100 Z"
                                repeatCount="indefinite"
                                dur="30s"
                            />
                        </path>
                    </svg>
                </div>
            </div>
        </Divider>
        <Content speed={0.4} offset={offset} factor={factor}>
            <Inner>
                <ContactMDX/>
            </Inner>
            <Footer/>
        </Content>
        <Divider speed={0.1} offset={offset} factor={factor}>
            <UpDown>
                <Svg icon="19" width={8} left="70%" top="20%"/>
                <Svg icon="20" width={8} left="25%" top="5%"/>
            </UpDown>
            <UpDownWide>
                <Svg icon="1" width={12} left="95%" top="50%"/>
                <Svg icon="2" width={6} left="85%" top="15%"/>
                <Svg icon="3" width={8} left="45%" top="10%"/>
            </UpDownWide>
            <Svg icon="1" width={6} left="4%" top="20%"/>
            <Svg icon="3" width={12} left="70%" top="60%"/>
            <Svg icon="4" width={12} left="20%" top="30%"/>
            <Svg icon="5" width={8} left="80%" top="70%"/>
        </Divider>
    </div>
)

export default Contact

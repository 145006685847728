import * as React from "react"
import {jsx} from "theme-ui"
import Divider from "../elements/divider"
import Inner from "../elements/inner"
import Content from "../elements/content"
import Svg from "./svg"
import {UpDown, UpDownWide} from "../styles/animations"
// @ts-ignore
import AboutMDX from "../sections/about"

const About = ({offset, factor = 1}: { offset: number; factor?: number }) => (
    <div>
        <Divider
            bg="divider"
            clipPath="polygon(0 16%, 100% 4%, 100% 82%, 0 94%)"
            speed={0.2}
            offset={offset}
            factor={factor}
        />
        <Divider speed={0.1} offset={offset} factor={factor}>
            <UpDown>
                <Svg icon="5" width={6} left="50%" top="75%"/>
                <Svg icon="6" width={8} left="70%" top="20%"/>
                <Svg icon="7" width={8} left="25%" top="5%"/>
                <Svg icon="8" width={24} left="80%" top="80%"/>
            </UpDown>
            <UpDownWide>
                <Svg icon="9" width={16} left="5%" top="80%"/>
                <Svg icon="10" width={12} left="95%" top="50%"/>
                <Svg icon="11" width={6} left="85%" top="15%"/>
                <Svg icon="12" width={8} left="45%" top="10%"/>
            </UpDownWide>
            <Svg icon="13" width={6} left="4%" top="20%"/>
            <Svg icon="14" width={12} left="70%" top="60%"/>
            <Svg icon="15" width={6} left="10%" top="10%"/>
            <Svg icon="18" width={12} left="20%" top="30%"/>
            <Svg icon="17" width={8} left="80%" top="70%"/>
        </Divider>
        <Content speed={0.4} offset={offset+0.2} factor={factor}>
            <Inner>
                <AboutMDX/>
            </Inner>
        </Content>
    </div>
)

export default About

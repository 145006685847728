/** @jsx jsx */
import {Image, jsx} from "theme-ui"
import Divider from "../elements/divider"
import Inner from "../elements/inner"
import Content from "../elements/content"
import Svg from "./svg"
import {UpDown, UpDownWide} from "../styles/animations"
// @ts-ignore
import Intro from "../sections/intro"
import * as React from "react";
import GoogleBadge from '../icons/google-play-badge.png'
import AppleBadge from '../icons/apple.png'

const Hero = ({offset, factor = 1}: { offset: number; factor?: number }) => (
    <div>
        <Divider speed={0.2} offset={offset} factor={factor}>
            <UpDown>
                <Svg icon="1" width={48} left="10%" top="20%"/>
                <Svg icon="2" width={48} left="60%" top="70%"/>
                <Svg icon="3" width={6} left="60%" top="15%"/>
            </UpDown>
            <UpDownWide>
                <Svg icon="4" width={16} left="80%" top="10%"/>
                <Svg icon="5" width={12} left="90%" top="50%"/>
                <Svg icon="6" width={16} left="70%" top="90%"/>
                <Svg icon="7" width={16} left="30%" top="65%"/>
                <Svg icon="8" width={16} left="28%" top="15%"/>
                <Svg icon="9" width={6} left="75%" top="10%"/>
                <Svg icon="10" width={8} left="45%" top="10%"/>
            </UpDownWide>
            <Svg icon="18" width={24} left="5%" top="70%"/>
            <Svg icon="12" width={6}   left="4%" top="20%"/>
            <Svg icon="13" width={12} left="50%" top="60%"/>
            <Svg icon="14" width={8} left="95%" top="90%"/>
            <Svg icon="15" width={24} left="40%" top="80%"/>
            <Svg icon="16" width={8} left="25%" top="5%"/>
            <Svg icon="17" width={64} left="95%" top="5%"/>
            <Svg icon="17" width={64} left="5%" top="90%"/>
            <Svg icon="19" width={6} left="10%" top="10%"/>
            <Svg icon="20" width={12} left="40%" top="30%"/>
            <Svg icon="21" width={16} left="10%" top="50%"/>
            <Svg icon="22" width={8} left="80%" top="70%"/>
        </Divider>
        <Content sx={{variant: `texts.bigger`}} speed={0.4} offset={offset} factor={factor}>
            <Inner>
                <Intro/>
                <a href={"https://apps.apple.com/tr/app/coin-order-book/id1593422107?l=tr"} target={"_blank"}><Image style={{width: 200}} src={AppleBadge}/></a>
                <a href={"https://play.google.com/store/apps/details?id=com.sulhadin.coinorderbook"} target={"_blank"}><Image style={{width: 200}} src={GoogleBadge}/></a>
            </Inner>
        </Content>
    </div>
)

export default Hero

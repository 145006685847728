import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Features`}</h2>
    <blockquote>
      <p parentName="blockquote">{`It is quite troublesome to follow, monitor and analyze the order book in any market. This application offers you many analyzes about the order limits of the coins you have added as your favorites.`}</p>
    </blockquote>
    <p>{`Here are some features;`}</p>
    <ul>
      <li parentName="ul">{`Creating dashboard`}</li>
      <li parentName="ul">{`Adding any cryptocurrency pair to your favorite list`}</li>
      <li parentName="ul">{`See their price in a colored way`}</li>
      <li parentName="ul">{`Monitor the regular order book with an extended rounding feature`}</li>
      <li parentName="ul">{`Monitor the highest order limits`}</li>
      <li parentName="ul">{`Monitor other analyses...`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
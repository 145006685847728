import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Coin Order B`}<span style={{
        "color": "#FF0000"
      }}>{`o`}</span><span style={{
        "color": "#00FF00"
      }}>{`o`}</span>{`k`}</h1>
    <p>{`Is an app that gives you an opportunity to see the critical SELL and BUY order limits before you trade.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
/** @jsx jsx */
import {Box, useColorMode, jsx, Image} from "theme-ui"

import GoogleBadge from '../icons/google-play-badge.png'
import AppleBadge from '../icons/apple.png'
import * as React from "react";


const Footer = () => {
    const [colorMode, setColorMode] = useColorMode()
    const isDark = colorMode === `dark`
    const toggleColorMode = (e: any) => {
        setColorMode(isDark ? `light` : `dark`)
    }

    return (
        <Box as="footer" variant="footer">

            <a
                href={"#"}
                sx={{variant: `link.toggle`, fontWeight: `semibold`, display: `block`, mx: `auto`, mb: 3}}
                onClick={toggleColorMode}
                type="button"
                aria-label="Toggle dark mode"
            >
                {isDark ? `Switch to Light Theme` : `Switch to Dark Theme`}
            </a>
            <a href={"https://apps.apple.com/tr/app/coin-order-book/id1593422107?l=tr"} target={"_blank"}><Image style={{width: 200}} src={AppleBadge}/></a>
            <a href={"https://play.google.com/store/apps/details?id=com.sulhadin.coinorderbook"} target={"_blank"}><Image style={{width: 200}} src={GoogleBadge}/></a>
            <div>

                Copyright &copy; {new Date().getFullYear()}. All rights reserved.
                | <a target={"_blank"}
                     href={"https://raw.githubusercontent.com/sulhadin/coinorderbook-app/main/static/privacy-policy?token=AALBBMCWCOG2H2KROWX7CGDBTY3Y4"}> Privacy
                Policy</a>
            </div>
        </Box>
    )
}

export default Footer
